import ArrowBack from "./ArrowBack";
import ArrowDown from "./ArrowDown";
import ArrowDownMin from "./ArrowDownMin";
import ArrowUp from "./ArrowUp";
import ArrowUpMin from "./ArrowUpMin";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import Cart from "./Cart";
import Check from "./Check";
import CheckShaped from "./CheckShaped";
import CheckSquare from "./CheckSquare";
import CheckSucc from "./CheckSucc";
import Close from "./Close";
import Cloud from "./Cloud";
import InputError from "./InputError";
import InputOk from "./InputOk";
import Info from "./Info";
import Lock from "./Lock";
import LogOut from "./LogOut";
import MobileMenuBtn from "./MobileMenuBtn";
import Minus from "./Minus";
import CompanyLogo from "./CompanyLogo";
import ErrorShaped from "./ErrorShaped";
import Spinner from "./Spinner";
import Success from "./Success";
import Faq from "./Faq";
import PDF from "./PDF";
import Plus from "./Plus";
import Router from "./Router";
import RubleSquare from "./RubleSquare";
import Search from "./Search";

const Icon = {
  ArrowBack,
  ArrowDown,
  ArrowDownMin,
  ArrowUp,
  ArrowUpMin,
  ArrowLeft,
  ArrowRight,
  Check,
  CheckSquare,
  CheckSucc,
  Cart,
  CheckShaped,
  Close,
  Cloud,
  InputError,
  InputOk,
  Info,
  Lock,
  LogOut,
  MobileMenuBtn,
  Minus,
  ErrorShaped,
  Spinner,
  Success,
  CompanyLogo,
  Faq,
  PDF,
  Plus,
  Router,
  RubleSquare,
  Search,
};

export default Icon;
