import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { MTSInput } from "components/mts-input";

export const SCont = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};

  & > img {
    display: block;
    min-width: 0;
    flex: 0 0 120px;
    height: 80px;
    margin-right: 16px;
    object-fit: contain;
  }

  & > div {
    &:first-of-type {
      min-width: 0;
      display: flex;
      flex-flow: column nowrap;
      flex: 0 1 260px;
      margin-right: 28px;

      & > div:nth-of-type(3) {
        & > span {
          color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
        }
      }
    }

    &:nth-of-type(2) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-right: 28px;
      flex: 1;

      & svg {
        cursor: pointer;
      }

      & ${MTSInput} {
        width: 64px;
        margin: 0 8px;

        & input {
          padding: 10px 6px;
        }
      }
    }

    &:last-of-type {
      margin-left: auto;
      cursor: pointer;
    }
  }
`;

export const SPrice = styled(Typography)`
  display: inline-block;
  padding-left: 24px;
  white-space: nowrap;
`;

export const SLinkMore = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
