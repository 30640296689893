import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import { Toast } from "components/toast/toast";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { defaultValBankInfo, schemaBank } from "./validation-schema";
import { MTSInput } from "components/mts-input";
import { useAddBankInfo } from "hooks/hooks";
import { IBankInfo } from "interface/common";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SControls, SWrap } from "./styles";

interface IForm {
  onClose: () => void;
  data?: IBankInfo | null;
}

export const FormBankInfo: React.FC<IForm> = ({ onClose, data }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: {
      ...defaultValBankInfo,
      ...data,
    },
    resolver: yupResolver(schemaBank),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const { mutate } = useAddBankInfo();

  const handleCloseForm = () => {
    onClose();
    reset();
  };

  const onSubmit = async (sbmtData) => {
    mutate(
      {
        ...sbmtData,
      },
      {
        onSuccess: () => {
          handleCloseForm();
          toast(<Toast title="Информация обновлена" />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
        onError: (error) => {
          toast(<Toast title={error?.message ?? "Информация не обновлена"} isError />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
      },
    );
  };

  return (
    <SWrap>
      <CustomTypography variant="h3_medium" font="comp">
        Укажите банковские реквизиты
      </CustomTypography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Spacer value="32px" />
        <MTSInput
          label="Расчетный счет"
          control={control}
          {...register("rassch_schet", {
            required: true,
          })}
          errorMessage={errors?.rassch_schet?.message}
        />
        <Spacer value="32px" />
        <MTSInput
          label="Наименование банка"
          placeholder="ОАО “Банк Инвест”"
          control={control}
          {...register("bank_name", {
            required: true,
          })}
          errorMessage={errors?.bank_name?.message}
        />
        <Spacer value="32px" />
        <MTSInput
          label="Корреспондентский счет"
          control={control}
          {...register("corr_schet", {
            required: true,
          })}
          errorMessage={errors?.corr_schet?.message}
        />
        <Spacer value="32px" />
        <MTSInput
          label="БИК"
          control={control}
          {...register("bik", {
            required: true,
          })}
          errorMessage={errors?.bik?.message}
        />
        <Spacer value="32px" />
        <SControls>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isDirty}
          >
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={handleCloseForm}>
            Отмена
          </MTSButton>
        </SControls>
        <Spacer value="24px" />
      </form>
    </SWrap>
  );
};
