export interface ILocationState {
  shopOrderId?: string;
}

export interface IRoles {
  id: number;
  name: string;
}

export interface IProfileResponse {
  createdTime: string;
  email: string;
  id: number;
  isActive: boolean;
  phone: string;
  tenant: {
    email: string;
    id: string;
    phone: string | null;
    title: string;
    services?: any[]; // нам не актуально для текущих задач
  };
  tenants: any;
  userName: string;
  roles: IRoles[];
  services_ch: string[]; // ['Поиск коммерческих потерь'] и тд
  current_tenant: any;
}

export interface IAccordionCustom {
  title?: string;
  text?: string;
  pdf?: IAccordionPdf;
  list?: string[];
}

export interface IAccordionPdf {
  text: string;
  icon: React.ReactNode;
  link: string;
  size: string;
}

export type IRegistrationRequest = {
  companyName: string;
  userName: string;
  inn: string;
  phone: string;
  email: string | null;
};

export type IConsultationRequest = {
  userName: string;
  phone: string;
  email: string | null;
};

export interface IBankInfo {
  bank_name: string | null;
  rassch_schet: string | null;
  corr_schet: string | null;
  bik: string | null;
}
export interface IFeedbackInfo {
  name: string | null;
  user_phone: string;
  user_email: string | null;
}
export interface IInformation {
  tenant_name: string | null;
  inn: string | null;
  kpp: string | null;
  ogrnip: string | null;
  ogrn: string | null;
  ur_adress: string | null;
  owner_name: string | null;
  phone: string;
  email: string | null;
  post_adress: string | null;
  phone_add: string;
  file: File;
  document_url: string | null;
  document_size: string | null;
}

export interface IInformationData extends FormData {
  append(name: string, value: string | boolean | Blob, file?: string): void;
}
export interface IShopCheckout {
  user_id: number;
  bank_info: IBankInfo | null;
  feedback_info: IFeedbackInfo | null;
  information: IInformation | null;
  type_user?: "Юридическое лицо" | "Индивидуальный предприниматель";
}

export enum EUserInfoCard {
  bank_info = "bank_info",
  feedback_info = "feedback_info",
  user_info = "user_info",
}

export interface IDeleteInfo {
  user_info_param: "bank_info" | "feedback_info" | "user_info";
}

export interface IShopProductOrder {
  id: number;
  quantity: number;
}

export interface IPlaceOrder {
  promocode: string;
  order: IShopProductOrder[];
  user_id: number | null;
}

export interface IShopRequest {
  categorys?: string[];
  types?: string[];
  conn_types?: string[];
}

export interface IPromoData {
  price: number;
  desc: string;
  disclamer: string;
}

export interface IShopProdPromo {
  promotion_id: number;
  promotion_bubble: string;
}
export enum EPriceType {
  "Фиксированная" = "Фиксированная",
  "Ежемесячная" = "Ежемесячная",
  "По запросу" = "По запросу",
}
export interface IShopProduct {
  id: number;
  name: string;
  type: string | null;
  category: string;
  conn_type: string;
  date: string; //"2020-12-12T00:00:00",
  image_url: string;
  price: number;
  price_type?: EPriceType;
  promotions: IShopProdPromo[];
  desc: string;
  tooltip: boolean;
  redirect: null | string;
  hide_flag: boolean;
}
export interface IPromocode {
  promo_percent: number;
}

type ICat = {
  [key: string]: string;
};

export interface IShopIndividualItem {
  category: string;
  characteristics: ICat | null;
  conn_type: ICat;
  date: string;
  desc: string;
  docs: ICat | null;
  features: ICat | null;
  id: number;
  image_url: string;
  name: string;
  price: number;
  type: string;
  photos: string[];
}
export interface IAskueRequest {
  categorys: string[];
  manufacturer: string[];
}

export interface IShopProd {
  id: number;
  quantity: number;
}

export type ICheckCartReq = {
  quantity: number;
  id: number;
}[];

export interface ICheckCartRes {
  id: number;
  name: string;
  type: string;
  category: string;
  conn_type: string;
  date: string;
  image_url: string;
  price: number;
  price_type?: EPriceType;
  quantity: number;
  promo_disclamer?: string;
  promo_desc?: string;
}

export interface ICardProduct extends IShopProduct {
  promo_disclamer: string;
  quantity: number;
}

export interface IDemoAskueCreateRequest {
  userName: string;
  phone: string;
  sn: string[];
}
