import styled from "@emotion/styled";
import { device } from "theme";

export const SSectionTitle = styled.h2<{ isWhite?: boolean }>`
  text-align: left;
  margin-bottom: 48px;

  ${(props) =>
    props.isWhite
      ? `
			& .MuiTypography-root {
			color: ${props.theme.mtsColor.text.inverted.lightMode};
			};
		`
      : ``}

  @media ${device.miniTabletNeg} {
    & .MuiTypography-root {
      font-size: 32px;
    }
  }
`;
