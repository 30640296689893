import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import theme from "theme";

import { ContextSettings } from "context/context-settings";
import { useCheckCart, useShopCatalog } from "hooks/hooks";
import { ROUTE_SHOP_CHECKOUT } from "stream-constants/route-constants";
import { login } from "services/auth";
import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { CartProductCard, CartServiceCard } from "components/shop/shopCart/cartItems";
import { SPrice } from "components/shop/shopCart/styles";
import { MTSButton } from "components/mts-button/mts-button";
import { NotificationBlock } from "components/notification-block/notification-block";
import { SectionTitle } from "components/section-title/section-title";
import { CustomTypography } from "components/customTypography/custom-typography";
import { Loader } from "components/spinner";
import { Toast } from "components/toast/toast";
import {
  emptyPrice,
  emptyPriceDisclaim,
  promoCategory,
  resultPrice,
  serviceCategory,
} from "components/shop/const";
import { makePayload, renderDisclaim, seo } from "./const";
import { SBox, SDisclaim, SGrid, SInfo } from "./styles";

export const ShopCart = () => {
  const navigate = useNavigate();
  const { cart, isAuthorized } = useContext(ContextSettings);

  const { data: catalog = [], isLoading, isError } = useShopCatalog();
  const isCartContainExistingGoods = Object.keys(cart)?.some(
    (a) => catalog.find((f) => f.id === parseInt(a)) != null,
  );
  const { data: items = [] } = useCheckCart(makePayload(cart), isCartContainExistingGoods);

  const uniqPromoDisclamers = [
    ...new Set(items.map((item) => (item.promo_disclamer ? item.promo_disclamer : ""))),
  ].filter(Boolean);

  const services = items.filter((a) => a.category === serviceCategory);
  const staticItems = catalog
    ?.filter((a) => Boolean(cart[a.id]) && !cart[a.id]?.hasOwnProperty("promo_visible"))
    .filter((a) => services?.find((f) => f.name === a.name) == null);
  const combo = [...(staticItems ?? []), ...services];
  const totalPrice = resultPrice(combo, cart);

  useEffect(() => {
    if (isError)
      toast(<Toast title="Ошибка запроса каталога" isError />, {
        progress: undefined,
        autoClose: 1500,
        hideProgressBar: true,
      });
  }, [isError]);

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SBox>
        {!isCartContainExistingGoods ? (
          <>
            <Spacer value="120px" />
            <NotificationBlock type="cart" />
          </>
        ) : (
          <div>
            <Back />
            <Spacer value="48px" />
            <SectionTitle title="Корзина" />
            <SGrid>
              <div>
                {staticItems?.map((a) => (
                  <CartProductCard
                    product={a}
                    quantity={cart[a.id]?.qty}
                    key={`device_${a.id}`}
                    type={a.category === serviceCategory ? "service" : "cart"}
                  />
                ))}
                {services?.map((b) => (
                  <CartServiceCard key={`service_${b.id}`} product={b} />
                ))}
                {uniqPromoDisclamers.map((string, index) => (
                  <SDisclaim key={index}>{renderDisclaim(string)}</SDisclaim>
                ))}
              </div>
              <div>
                <section>
                  <CustomTypography
                    variant="c1_regular"
                    styles={{ color: theme.mtsColor.text.secondary.lightMode }}
                  >
                    ИТОГО
                  </CustomTypography>
                  <SPrice variant="h4_bold">{totalPrice}</SPrice>
                </section>
                <MTSButton
                  size="L"
                  variant="primary"
                  onClick={
                    isAuthorized
                      ? () =>
                          navigate(`/${ROUTE_SHOP_CHECKOUT}`, {
                            state: combo
                              .map((product) => ({
                                id: product.id,
                                name: product.name,
                                category: (product as any).promo_desc
                                  ? promoCategory
                                  : product.category,
                                qty: cart[product.id]?.qty || 1,
                                price: product.price,
                                price_type: product.price_type ?? undefined,
                              }))
                              .filter((a) => a !== null),
                          })
                      : (e) => login(e, "cart")
                  }
                >
                  Перейти к оформлению
                </MTSButton>
                {!isAuthorized ? (
                  <>
                    <Spacer value="24px" />
                    <SInfo variant="c2_regular" font="comp">
                      Для оформления товара необходимо пройти авторизацию
                    </SInfo>
                  </>
                ) : null}
                {totalPrice.includes(emptyPrice) ? (
                  <>
                    <Spacer value="24px" />
                    <SInfo variant="c2_regular" font="comp">
                      {emptyPriceDisclaim}
                    </SInfo>
                  </>
                ) : null}
              </div>
            </SGrid>
          </div>
        )}
        <Spacer value="120px" />
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : null}
      </SBox>
    </>
  );
};
