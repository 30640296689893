import { useContext } from "react";
import { Link } from "react-router-dom";

import { MTSButton } from "components/mts-button/mts-button";
import {
  ROUTE_MAIN_PAGE,
  ROUTE_USER_AGREEMENT,
  ROUTE_MAILTO,
  ROUTE_ASKUE_PAGE,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_MINING_PAGE,
  ROUTE_OFFER_EQUIPMENT,
  ROUTE_OFFER_LICENSE,
  ROUTE_SHOP,
} from "stream-constants/route-constants";
import { ContextSettings } from "context/context-settings";
import { LIST_ROLES } from "utils/roles";
import { handleLogout, login, redirectToLk } from "services/auth";
import Icon from "components/icons";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SBtns, SItem, SMenu, SOutBtn, SOverlay } from "./styles";

const mobileMenuLink: Array<{ link: string; title: string }> = [
  {
    link: `${ROUTE_MAIN_PAGE}`,
    title: "Главная страница",
  },
  {
    link: `${ROUTE_COMMERCIAL_LOSSES_PAGE}`,
    title: "Поиск коммерческих потерь",
  },
  {
    link: `${ROUTE_ASKUE_PAGE}`,
    title: "Облачная платформа учета",
  },
  {
    link: `${ROUTE_MINING_PAGE}`,
    title: "Выявление майнинговых ферм",
  },
  {
    link: `${ROUTE_SHOP}`,
    title: "Магазин",
  },
  {
    link: `${ROUTE_USER_AGREEMENT}`,
    title: "Обработка персональных данных",
  },
  {
    link: `${ROUTE_OFFER_EQUIPMENT}`,
    title: "Оферта на поставку оборудования",
  },
  {
    link: `${ROUTE_OFFER_LICENSE}`,
    title: "Оферта на лицензии EnergyTool",
  },
];

export const MobileMenu = ({ isOpen, setOpen }) => {
  const { isAuthorized, setIsAuthorized, roles } = useContext(ContextSettings);
  const isCustomer = roles.includes(LIST_ROLES.CUSTOMER);

  return (
    <>
      {isOpen ? <SOverlay /> : null}
      <SMenu isOpen={isOpen}>
        <ul>
          {mobileMenuLink.map((item, index) => {
            return (
              <SItem key={index}>
                <Link to={item.link} onClick={() => setOpen(false)}>
                  <CustomTypography variant="p3_regular">{item.title}</CustomTypography>
                </Link>
              </SItem>
            );
          })}
          <SItem key={mobileMenuLink.length}>
            <a href={ROUTE_MAILTO}>
              <CustomTypography variant="p3_regular">Связаться с поддержкой</CustomTypography>
            </a>
          </SItem>
        </ul>
        <SBtns>
          <MTSButton size="L" onClick={() => setOpen(true)}>
            Заказать консультацию
          </MTSButton>
          {!isAuthorized ? (
            <MTSButton size="M" variant="outline_dark" onClick={login}>
              Войти
            </MTSButton>
          ) : isCustomer ? (
            <SOutBtn
              size="M"
              variant="outline_dark"
              onClick={() => handleLogout(setIsAuthorized)}
              icon={<Icon.LogOut sx={{ width: 24, height: 24 }} />}
            >
              Выйти
            </SOutBtn>
          ) : (
            <MTSButton size="M" variant="outline_dark" onClick={redirectToLk}>
              Личный кабинет
            </MTSButton>
          )}
        </SBtns>
      </SMenu>
    </>
  );
};
