import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  IConsultationRequest,
  IRegistrationRequest,
  IShopIndividualItem,
  IShopProduct,
  IShopRequest,
  IBankInfo,
  IDeleteInfo,
  IFeedbackInfo,
  IShopCheckout,
  IPlaceOrder,
  IPromocode,
  ICheckCartRes,
  ICheckCartReq,
  IDemoAskueCreateRequest,
} from "interface/common";
import { getSubmitFormConsultation } from "services/form-consultation";
import {
  addBankInfo,
  addFeedbackInfo,
  addInformationFl,
  addInformationUl,
  deleteInfo,
  getCheckPromocode,
  getShopCheckout,
  placeOrder,
} from "services/shop-checkout";
import { registrationUser } from "services/form-registration";
import { getShopCatalogue, getShopItem, shopCheckCart } from "services/shop";
import { demoAskueCreate } from "services/demo-askue";

export enum Keys {
  DEMO = "api/demo",
  USERS = "api/users",
  SHOP_CHECKOUT = "api/shop/checkout",
  SHOP_PROMOCODE = "/api/shop/get_promo",
  SHOP = "api/shop",
  CART_CHECK = "/api/shop/cart_check",
  ASKUE = "api/energosphere",
}

export function useSubmitFormConsultation() {
  return useMutation<any, any, IConsultationRequest>((a) => getSubmitFormConsultation(a), {
    mutationKey: [Keys.USERS, "consult"],
  });
}

export function useRegistrationUser() {
  return useMutation<any, any, IRegistrationRequest>((a) => registrationUser(a), {
    mutationKey: [Keys.DEMO, "create"],
  });
}

export function useShopCheckout() {
  return useQuery<any, any, IShopCheckout>([Keys.SHOP_CHECKOUT], () => getShopCheckout());
}

export function useAddBankInfo() {
  const queryClient = useQueryClient();
  return useMutation<any, any, IBankInfo>((a) => addBankInfo(a), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.SHOP_CHECKOUT]);
    },
  });
}

export function useFeedbackInfo() {
  const queryClient = useQueryClient();
  return useMutation<any, any, IFeedbackInfo>((a) => addFeedbackInfo(a), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.SHOP_CHECKOUT]);
    },
  });
}

export function useInformationFl() {
  const queryClient = useQueryClient();
  return useMutation<any, any, any>((a) => addInformationFl(a), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.SHOP_CHECKOUT]);
    },
  });
}

export function useInformationUl() {
  const queryClient = useQueryClient();
  return useMutation<any, any, any>((a) => addInformationUl(a), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.SHOP_CHECKOUT]);
    },
  });
}

export function useDeleteInfo() {
  const queryClient = useQueryClient();
  return useMutation<any, any, IDeleteInfo>((a) => deleteInfo(a), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.SHOP_CHECKOUT]);
    },
  });
}

export function usePlaceOrder() {
  return useMutation<number, any, IPlaceOrder>([Keys.SHOP_CHECKOUT], (a) => placeOrder(a));
}

export function useCheckPromocode(promo: string, isEnabled = false) {
  return useQuery<any, { message?: string }, IPromocode>(
    [Keys.SHOP_PROMOCODE, promo],
    () => getCheckPromocode(promo),
    {
      enabled: isEnabled,
      retry: 1,
    },
  );
}

export function useShopCatalog(filter?: IShopRequest) {
  return useQuery<any, any, IShopProduct[]>([Keys.SHOP, "catalog", filter], () =>
    getShopCatalogue(filter),
  );
}

export function useCatalogItem(id: string | number) {
  return useQuery<any, any, IShopIndividualItem>([Keys.SHOP, id], () => getShopItem(id));
}

export function useCheckCart(data: ICheckCartReq, isEnabled = false) {
  //   const queryKeys = data.map((a) => a.id);
  const queryKeys = data.map((a) => {
    const copy = { ...a };
    //@ts-ignore
    delete copy["quantity"];
    return copy;
  });
  return useQuery<any, any, ICheckCartRes[]>(
    [Keys.CART_CHECK, queryKeys],
    () => shopCheckCart(data),
    {
      enabled: isEnabled,
    },
  );
}

export function useCreateDemoAskue() {
  return useMutation<any, any, IDemoAskueCreateRequest>((a) => demoAskueCreate(a));
}
