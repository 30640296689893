import useStyles from "./styles";
  
interface AccordionIconProps {
  isActive?: boolean
}

const AccordionIcon = ( props: AccordionIconProps ) => {
  const { isActive } = props;
  const classes = useStyles();

  return (
    <div className={classes.iconWrap}>
      <span className={`${classes.icon} ${isActive ?? classes.active}`}>
      </span>
    </div>
  );
};

export default AccordionIcon;
