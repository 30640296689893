import { Modal, Box } from "@mui/material";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { device } from "theme";

export const SOuter = styled(Modal)`
  & .MuiBackdrop-root {
    background: ${rgba("#111", 0.25)};
  }
`;

export const SModBox = styled(Box)<{ maxWidth?: number }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "inherit")};
  height: auto;
  background-color: ${(props) => props.theme.mtsColor.background.modal.lightMode};
  border-radius: 16px 16px 0px 0px;
  outline: none;

  @media ${device.miniTabletPlus} {
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "440px")};
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
  }
`;

export const SContent = styled.div`
  max-height: 95vh;
  padding: 24px 24px 40px 24px;
  overflow-y: auto;

  @media ${device.miniTabletPlus} {
    padding-bottom: 24px;
  }
`;

export const SClose = styled.div`
  display: none;
  position: absolute;
  top: 20px;
  right: -40px;

  & .MuiSvgIcon-root {
    fill: #fff;
    cursor: pointer;
  }

  @media ${device.miniTabletPlus} {
    display: block;
  }
`;
