import React from "react";
import styled from "@emotion/styled";

import { CustomWidthTooltip } from "components/tooltip/tooltip";
import Icon from "components/icons";
import { SRadioLabel, SRadioButton, SLabel, SPlace, wrap, SHelper, SLabelWrap } from "./styles";

interface IRadio {
  checked: boolean;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string | React.ReactNode;
  label?: string;
  labelInfo?: string;
  className?: string;
  id: string;
  name?: string;
  style?: React.CSSProperties;
  hasError?: boolean;
  leftPhr?: boolean;
}

export const SRadio: React.FC<IRadio> = ({
  checked,
  value,
  onChange,
  text = "placeholder",
  label,
  className = "",
  id,
  name = "radio",
  style,
  hasError,
  leftPhr = false,
  labelInfo,
}) => {
  return (
    <div className={className} style={{ ...style }}>
      {label ? (
        <SLabelWrap>
          <SLabel className="radio__label">{label}</SLabel>
          {labelInfo ? (
            <CustomWidthTooltip
              title={<div style={{ fontWeight: 400 }}>{labelInfo}</div>}
              arrow
              placement="right"
            >
              <span>
                <Icon.Info sx={{ width: 16, height: 16, margin: "0 0 0 8px", alignSelf: "end" }} />
              </span>
            </CustomWidthTooltip>
          ) : null}
        </SLabelWrap>
      ) : null}

      <SRadioLabel>
        <SRadioButton
          type="radio"
          name={name}
          id={id}
          value={value}
          checked={checked}
          onChange={onChange}
          hasError={hasError}
          leftPlhlr={leftPhr}
        />
        <SHelper leftPhr={leftPhr} />
        <SPlace className="radio__placeholder" flexOrder={leftPhr ? -1 : 2}>
          {text}
        </SPlace>
      </SRadioLabel>
    </div>
  );
};

export const Radio = styled(SRadio)`
  ${wrap};
`;
