import styled from "@emotion/styled";

import { MTSButton } from "components/mts-button/mts-button";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SWrap = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;

  & ${MTSButton} {
    max-width: 100%;
    width: 100%;

    & button {
      max-width: 100%;
      width: 100%;
      height: 46px !important;
    }
  }
`;

export const SResult = styled.div`
  text-align: center;

  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondaryLink.lightMode};
  }
`;

export const SBoxWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
  text-align: left;
`;

export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 250px;
  min-height: 50px;
  overflow-y: auto;
`;

export const STitle = styled(CustomTypography)`
  display: inline-block;
`;
