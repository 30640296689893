import styled from "@emotion/styled";

export const STags = styled.div`
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  & span {
    display: inline-block;
    padding: 2px 8px;
    white-space: nowrap;
    background: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    border-radius: 4px;
  }
`;

const barHeight = 4;
const padTop = 24;
const tabHeight = 68;

export const STabHead = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: ${tabHeight}px;
  margin: 0 auto;
  max-width: 1048px;
`;

export const STabItem = styled.div<{ isActive: boolean }>`
  height: 100%;
  padding: 22px ${padTop}px;
  cursor: pointer;

  & > span {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    user-select: none;

    &:after {
      content: "";
      position: absolute;
      top: calc(${tabHeight}px - ${padTop}px - ${barHeight / 2}px);
      left: 0;
      width: 100%;
      height: ${barHeight}px;
      background-color: ${(props) => (props.isActive ? "red" : "transparent")};
    }
  }
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 14px 24px;
  & > div {
    &:first-of-type {
      flex: 1 1 auto;
      margin-right: 16px;
    }

    &:last-of-type {
      margin-left: auto;
      font-weight: 500;
    }

    & > a {
      font-weight: 400;
      text-decoration: none;
      color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    }
  }
`;

export const STabBody = styled.div`
  max-width: 100%;

  & > ${SRow} {
    &:nth-child(2n) {
      background: ${(props) => props.theme.mtsColor.background.primary.lightMode};
    }
  }
`;
