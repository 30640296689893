import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  iconWrap: {
    height: "32px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.mtsColor.background.secondary.lightMode,
    borderRadius: "6px",
  },
  icon: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYgMUMxNiAxLjU1MjI5IDE1LjU1MjMgMiAxNSAyTDEgMkMwLjQ0NzcxNSAyIC0xLjM3OTVlLTA4IDEuNTUyMjggMCAxQzEuMzc5NWUtMDggMC40NDc3MTUgMC40NDc3MTUgLTEuMzc5NWUtMDggMSAwTDE1IDMuNDk2OTJlLTA3QzE1LjU1MjMgMy42MzQ4N2UtMDcgMTYgMC40NDc3MTYgMTYgMVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "16px",
  },
  active: {
    backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMTVDNyAxNS41NTIzIDcuNDQ3NzIgMTYgOCAxNkM4LjU1MjI5IDE2IDkgMTUuNTUyMyA5IDE1VjlIMTVDMTUuNTUyMyA5IDE2IDguNTUyMjkgMTYgOEMxNiA3LjQ0NzcyIDE1LjU1MjMgNyAxNSA3SDlWMUM5IDAuNDQ3NzE1IDguNTUyMjggMCA4IDBDNy40NDc3MSAwIDcgMC40NDc3MTUgNyAxTDcgN0gxQzAuNDQ3NzE1IDcgMCA3LjQ0NzcxIDAgOEMwIDguNTUyMjggMC40NDc3MTUgOSAxIDlIN0w3IDE1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==)",
  },
}));

export default useStyles;
