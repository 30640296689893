import { ICart } from "context/context-settings";
import { formatPrice } from "./shopCard/utils";
import { ILocationState } from "pages/shopCheckout/shopCheckoutPage";
import { EPriceType, ICheckCartRes, IShopProduct } from "interface/common";

export const serviceCategory = "Услуги и лицензии";
export const promoCategory = "PROMO";
export const emptyPrice = "Цена по запросу";
export const emptyPriceDisclaim =
  "*При расчете стоимости комплексных решений окончательная цена формируется после обратной связи с нашим менеджером";

export const renderPriceProduct = (val: number, isPromo?: boolean) => {
  if (val === -1) return emptyPrice;
  return `${formatPrice(val)} ${isPromo ? "*" : ""}`;
};

const reducer = (arr: any[]) =>
  arr.reduce((tot, cur) => tot + (cur.price === -1 ? 0 : cur.price) * (cur.qty ?? 1), 0);

export const resultPrice = (items: (IShopProduct | ICheckCartRes)[], cart: ICart) => {
  if (items.some((it) => it.price_type === EPriceType["По запросу"])) {
    return `${emptyPrice}*`;
  } else {
    const rearrange = items.map((a) => ({ ...a, qty: cart[a.id]?.qty }));
    return formatPrice(reducer(rearrange));
  }
};

export const resultPriceOrder = (cart: ILocationState[]) => {
  if (cart.some((a) => a.price_type === EPriceType["По запросу"])) {
    return emptyPrice;
  } else {
    return reducer(cart);
  }
};
