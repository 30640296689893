import { useState } from "react";
import { Helmet } from "react-helmet";

import Icon from "components/icons";
import { MobileMenu } from "components/mobile-menu/mobile-menu";
import { SBtn } from "./styles";

export const MobMenuBtn = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <SBtn onClick={() => setOpen(!isOpen)}>
        <Helmet>
          <body className={isOpen ? "body-hidden" : ""} />
        </Helmet>
        {isOpen ? (
          <Icon.Close sx={{ width: 32, height: 32, fill: "#fff" }} />
        ) : (
          <Icon.MobileMenuBtn sx={{ width: 24, height: 24, fill: "#fff" }} />
        )}
      </SBtn>
      <MobileMenu isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};
