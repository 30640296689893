import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { SectionTitle } from "components/section-title/section-title";
import { ProductCard } from "components/product-card/product-card";
import { GridInfo } from "components/grid-info/grid-info";
import { GridInfoCard } from "components/grid-info-card/grid-info-card";
import { AccordionCustom } from "components/accordion-custom/accordion-custom";
import IntroSection from "components/intro-section";
import { PromoSection } from "components/section-promo/section-promo";
import { MTSList } from "components/mts-list/mts-list";
import { AdditInfoList } from "components/additional-info-list/additional-info";
import { Spacer } from "components/spacer/spacer.component";
import { AskuePromo } from "components/section-promo/subComponent/akue-promo";

import { SAdvantage, SContainer, SGrid, SListSection, SMain, SSection } from "./styles";
import { ROUTE_SHOP } from "stream-constants/route-constants";
import { promoQuery } from "pages/shop/const";
import {
  accordionInfo,
  additionalInfo,
  advantageData,
  energySphereList,
  productsCard,
  seo,
} from "./const";

import bgImg from "./images/askue-landing-bg.png";
import promoSectionBgImg from "./images/promo-section-bg.png";

export const LandingAskue = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <IntroSection
          title={"Облачная\nплатформа учета"}
          text="Исключите безучетное потребление электроэнергии"
          subtext="Без закупки серверов и организации дата-центров, без подключения серверных стоек и конфигурирования операционных систем. Вам остается лишь запустить на облачном сервере программу, которая будет экономить Ваше время."
          withConsultBtn={true}
          bgImg={bgImg}
          isFullScreen
        />
        <SAdvantage>
          <SContainer>
            <SectionTitle
              title="Решение от МТС полностью соответствует законодательству РФ"
              styles={{ maxWidth: "893px" }}
            />
            <div>
              <GridInfo isShort>
                {advantageData.map((item, index) => {
                  return (
                    <GridInfoCard
                      key={index}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                  );
                })}
              </GridInfo>
            </div>
          </SContainer>
        </SAdvantage>
        <SListSection>
          <SContainer>
            <SectionTitle title="Энергосфера в облаке МТС" />
            <section>
              <MTSList items={energySphereList} />
            </section>
            <Spacer value="32px" />
            <AdditInfoList data={additionalInfo} />
          </SContainer>
        </SListSection>
        <PromoSection bgImg={promoSectionBgImg} maxWidth="75%">
          <AskuePromo handleClickBtn={() => navigate(`/${ROUTE_SHOP}${promoQuery}`)} />
        </PromoSection>
        <SSection>
          <SContainer>
            <SectionTitle title="Подключите другие наши продукты" />
            <SGrid>
              {productsCard.map((item, index) => {
                return (
                  <ProductCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    nameBtn={item.nameBtn}
                    isCallbackBlock={item.isCallbackBlock}
                    img={item.img}
                    link={item.link}
                  />
                );
              })}
            </SGrid>
          </SContainer>
        </SSection>
        <SSection>
          <SContainer>
            <AccordionCustom data={accordionInfo} />
          </SContainer>
        </SSection>
      </SMain>
    </>
  );
};
