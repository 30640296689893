import { useEffect, useState } from "react";

import { declWords } from "utils/declensionWords";
import { ChxRenderer } from "./checkbox-renderer";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SBtns, SResult, SWrap } from "./styles";

export interface IColumn {
  column: string;
  value: any;
}

export type IKek = { [key: string]: string[] };
export type IItem = {
  [key: string]:
    | {
        id: number;
        name: string | null;
        friendlyName?: string;
      }[]
    | undefined;
};

interface IProp {
  dataLength: number;
  onApplyFilter: ({ filtersArr }) => void;
  defaultFilter: IKek;
  onResetFilter?: () => void;
  className?: string;
  scheme: IItem;
  titleByKey: { [key: string]: string };
}

export const PageFilter: React.FC<IProp> = ({
  dataLength,
  onApplyFilter,
  defaultFilter,
  onResetFilter,
  className = "",
  scheme,
  titleByKey,
}) => {
  const [dataRes, setDataRes] = useState<IKek>({
    ...defaultFilter,
  });

  const checkAll = (column: string, list: any[], makeReset?: boolean) => {
    if (makeReset) {
      setDataRes((s) => {
        return {
          ...s,
          [column]: [],
        };
      });
    } else {
      setDataRes((s) => {
        const vals = list.map((a) => a.name);
        return {
          ...s,
          [column]: vals,
        };
      });
    }
  };

  const toggleChbx = (column: string, name: string) => {
    setDataRes((s) => {
      const target = s[column] ?? [];
      const arr = target?.includes(name) ? target?.filter((a) => a !== name) : [...target, name];

      return {
        ...s,
        [column]: arr,
      };
    });
  };

  const handleApplyFilter = (filters) => {
    onApplyFilter({
      filtersArr: filters,
    });
  };

  const handleResetFilter = () => {
    setDataRes((s) => {
      const keys = Object.keys(s);
      return keys.reduce((sum, cur) => ({ ...sum, [cur]: [] }), {}) as any;
    });
    handleApplyFilter([]);
    onResetFilter?.();
  };

  useEffect(() => {
    setDataRes({
      ...defaultFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultFilter)]);

  useEffect(() => {
    let filtersArray: IColumn[] = Object.entries(dataRes).map(([key, val]) => {
      return { column: key, value: val };
    });
    handleApplyFilter(filtersArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRes]);

  return (
    <>
      <SWrap>
        {Object.keys(scheme).map((a) => {
          return (
            <ChxRenderer
              key={`${a}_${scheme[a]}`}
              targetArray={scheme[a]}
              filterKey={a}
              isAllChecked={(scheme[a] ?? []).length === dataRes[a]?.length}
              toggleAll={checkAll}
              toggleSingle={toggleChbx}
              currFilters={dataRes}
              titleByKey={titleByKey}
            />
          );
        })}
      </SWrap>
      <SBtns>
        <MTSButton size="M" variant="secondary" onClick={handleResetFilter}>
          Сбросить все
        </MTSButton>
      </SBtns>
      <Spacer value="24px" />
      <SResult>
        <CustomTypography variant="c1_regular" font="comp">
          Найдено {declWords(dataLength, ["результат", "результата", "результатов"])}
        </CustomTypography>
      </SResult>
    </>
  );
};
