const URL = window.location.hostname;

export const isDevMode = (): boolean => process.env.NODE_ENV === "development";

export const WELCOME_PAGE_URL = isDevMode() ? "https://localhost.mts.ru" : `https://${URL}`;
export const MAIN_URL = isDevMode()
  ? "https://lk.energytool-dev.mts.ru/api"
  : `https://lk.${URL}/api`;

export const LOG_IN = isDevMode()
  ? `${MAIN_URL}/fake/9651478026`
  : `${MAIN_URL}/auth/login?return_uri=https://lk.${URL}/lk`;

export const LOG_OUT = `${MAIN_URL}/auth/logout`;
export const LOGOUT_MTS_BY_ID = (param: number | string) =>
  `https://login.mts.ru/amserver/UI/Logout?goto=${param}`;

export const returnToSubpath = (sub: string) =>
  isDevMode()
    ? `${MAIN_URL}/fake/9651478026`
    : `${MAIN_URL}/auth/login?return_uri=https://${URL}/${sub}`;

export const GOTO_LK = `https://lk.${URL}/lk`;
export const LOG_IN_VALIDATE = `${MAIN_URL}/auth/login/validate`;
export const GET_ABOUT = `${MAIN_URL}/users/me`;

export const FORM_CONSULTATION = `${MAIN_URL}/users/consult`;
export const FORM_REGISTRATION = `${MAIN_URL}/demo/create`;
export const SHOP = `${MAIN_URL}/shop`;
export const GET_SHOP_CHECKOUT = `${SHOP}/checkout`;
export const USER_BANK_INFO = `${SHOP}/user_bank_info`;
export const USER_FEEDBACK_INFO = `${SHOP}/user_feedback`;
export const USER_INFO_FL = `${SHOP}/user_info_fl`;
export const USER_INFO_UL = `${SHOP}/user_info_ul`;
export const DELETE_USER_INFO = `${SHOP}/delete_info`;
export const PLACE_ORDER = `${SHOP}/order`;
export const DOWNLOAD_FILE_ORG = `${SHOP}/download_file`;
export const GET_PROMO = `${SHOP}/get_promo`;
export const GET_CART_CHECK = `${SHOP}/cart_check`;
export const ASKUE_DEMO = `${MAIN_URL}/energosphere/demo`;
