import { useContext } from "react";

import { ROUTE_MAIN_PAGE, ROUTE_SHOP } from "stream-constants/route-constants";
import { handleLogout, login, redirectToLk } from "services/auth";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ContextSettings } from "context/context-settings";
import { LIST_ROLES } from "utils/roles";
import { Logo } from "components/logo/logo.component";
import { MTSButton } from "components/mts-button/mts-button";
import { MobMenuBtn } from "components/mobile-menu/components/mobile-menu-btn";
import Icon from "components/icons";
import { Cart } from "./components/cart/cart";
import { DeskMenu } from "./components/desk-menu/desk-menu";
import { SBody, SBtnLogIn, SContainer, SHeader, SLogo, SNav, SOutBtn, SShop } from "./styles";

interface IHeader {
  view?: "blue" | null;
}

export const Header = (props: IHeader) => {
  const { view } = props;
  const { isAuthorized, setIsAuthorized, roles } = useContext(ContextSettings);
  const { isDesktop } = useBreakpoint();

  const isCustomer = roles.includes(LIST_ROLES.CUSTOMER);

  return (
    <SHeader bg={view}>
      <SContainer>
        <SBody>
          <SLogo to={ROUTE_MAIN_PAGE}>
            <Logo isLight />
          </SLogo>
          <SNav>
            {isDesktop() ? (
              <>
                <DeskMenu />
                <SShop to={ROUTE_SHOP}>Магазин</SShop>
              </>
            ) : null}

            <SBtnLogIn>
              <Cart />
              {!isAuthorized ? (
                <MTSButton size="M" variant="primary" onClick={login}>
                  Войти
                </MTSButton>
              ) : isCustomer ? (
                <SOutBtn
                  size="M"
                  variant="outline_light"
                  onClick={() => handleLogout(setIsAuthorized)}
                  icon={<Icon.LogOut sx={{ width: 24, height: 24 }} />}
                >
                  Выйти
                </SOutBtn>
              ) : (
                <MTSButton size="M" variant="primary" onClick={redirectToLk}>
                  Личный кабинет
                </MTSButton>
              )}
            </SBtnLogIn>
            {!isDesktop() ? (
              <div>
                <MobMenuBtn />
              </div>
            ) : null}
          </SNav>
        </SBody>
      </SContainer>
    </SHeader>
  );
};
