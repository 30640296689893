import styled from "@emotion/styled";

export const SWrap = styled.div`
  text-align: left;
  overflow-y: auto;
  width: 100%;

  & section {
    display: flex;
    gap: 24px;

    & > div {
      flex-basis: 304px;
    }
  }
`;

export const SControls = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 16px;

  & button {
    width: 100%;
  }
`;

export const SSpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`;

export const SFieldAdress = styled.div`
  display: flex;
  gap: 24px;

  & > div {
    &:not(:last-child) {
      width: 82px;
    }

    &:last-child {
      width: 160px;
    }
  }
`;

export const SFieldBlock = styled.div`
  margin-bottom: 24px;

  & > label {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  & a {
    text-align: left;
    text-decoration: none;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
`;
