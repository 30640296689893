import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const SThumb = styled.div`
  height: 72px;
  width: 76px;
  flex: 0 0 76px;
  border-radius: 4px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:focus-visible {
    outline: none !important;
  }
`;

export const SImg = styled.div`
  width: 508px;
  height: 400px;
`;

export const scrollbarWidth = 4;

export const customScrollHorisontal = (theme, height = scrollbarWidth) => css`
  &::-webkit-scrollbar {
    height: ${height}px;
    border-radius: 4px 4px 4px 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.mtsColor.background.stroke.lightMode};
    border-radius: 4px 4px 4px 4px;
  }
`;
