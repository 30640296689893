import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const wrap = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SRadioLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const SHelper = styled.div<{ leftPhr?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) =>
    props.leftPhr
      ? css`
          left: auto;
          right: 0;
        `
      : css`
          right: auto;
          left: 0;
        `}

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid ${(props) => props.theme.mtsColor.grey.greyClay};
  transition: border 0.2s linear;
`;
export const SRadioButton = styled.input<{ hasError?: boolean; leftPlhlr?: boolean }>`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  ${(props) =>
    props.leftPlhlr
      ? css`
          margin-left: 18px;
        `
      : css`
          margin-right: 18px;
        `}
  cursor: pointer;

  &:hover:not(:checked) ~ ${SHelper} {
    border-color: ${(props) => props.theme.mtsColor.red};
  }

  ${(props) =>
    props.checked &&
    `
    &:checked + ${SHelper} {
      border-color: ${props.theme.mtsColor.red};
	  border-width: 4px;

      &:after {
        background: ${props.theme.mtsColor.red};
      }
    }
  `}

  ${(props) =>
    props.hasError &&
    css`
      & ~ ${SHelper} {
        border-color: ${props.theme.mtsColor.red} !important;
      }
    `}
`;

export const SLabelWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 20px;
  margin-bottom: 20px;
`;

export const SLabel = styled.span`
  min-width: 0;
  min-height: 0;
  flex: 0 0 auto;
  display: inline-block;
  font-size: 14px;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  white-space: nowrap;
  user-select: none;
`;

export const SPlace = styled.div<{ flexOrder?: number }>`
  font-size: 14px;
  color: ${(props) => props.theme.mtsColor.text.headline.lightMode};
  user-select: none;
  order: ${(props) => props.flexOrder};
`;
