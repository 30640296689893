import { useNavigate } from "react-router-dom";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { SPlace, SInfo } from "./style";
import Icon from "components/icons";
import theme from "theme";
import { ROUTE_SHOP } from "stream-constants/route-constants";
import { CustomTypography } from "components/customTypography/custom-typography";

interface IProp {
  type: "cart" | "success";
}

// FIXME сейчас здесь много хардкода
export const NotificationBlock: React.FC<IProp> = ({ type }) => {
  const navigate = useNavigate();

  return type === "cart" ? (
    <SPlace>
      <Icon.Cart sx={{ width: 128, height: 128 }} />
      <Spacer value="40px" />
      <CustomTypography variant="h2_medium" font="comp">
        Корзина пуста
      </CustomTypography>
      <Spacer value="16px" />
      <SInfo variant="p4_regular" font="comp">
        Воспользуйтесь каталогом, чтобы найти интересующие вас товары
      </SInfo>
      <Spacer value="40px" />
      <MTSButton size="L" variant="outline" onClick={() => navigate(`/${ROUTE_SHOP}`)}>
        Вернуться на главную
      </MTSButton>
    </SPlace>
  ) : type === "success" ? (
    <SPlace iconColor="green">
      <Icon.Success
        sx={{ width: 128, height: 128, fill: theme.mtsColor.accent.positive.lightMode }}
      />
      <Spacer value="40px" />
      <CustomTypography variant="h2_medium" font="comp">
        Заявка успешно отправлена
      </CustomTypography>
      <Spacer value="16px" />
      <SInfo variant="p4_regular" font="comp">
        Мы свяжемся с вами в течение рабочего дня с 9:00 до 18:00
      </SInfo>
      <Spacer value="40px" />
      <MTSButton size="L" variant="outline" onClick={() => navigate(`/${ROUTE_SHOP}`)}>
        Вернуться в магазин
      </MTSButton>
    </SPlace>
  ) : null;
};
