import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SMain = styled.main`
  padding-top: 64px;
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 48px 20px;
  width: 100%;
`;

export const SWrap = styled.section`
  display: flex;
  gap: 32px;
`;

export const SInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 32px;
`;

export const SItem = styled.div<{ isEmpty?: boolean }>`
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  border-radius: 8px;

  ${(props) => (!props.isEmpty ? `padding: 24px;` : ``)}

  ${(props) =>
    props.isEmpty
      ? `
	  & > div {
		width: 100%;
		display: flex;
		padding: 24px;
		align-items: center;
		gap: 5px;
		cursor: pointer;

		& > svg {
			& path {
				fill: ${props.theme.mtsColor.text.secondary.darkMode};
			}
		}

		& > span {
		  color: ${props.theme.mtsColor.text.secondary.darkMode};
		}
	  }
	  `
      : `
	  width: 100%;`};
`;

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const SControl = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    cursor: pointer;
  }

  & > svg {
    fill: ${(props) => props.theme.mtsColor.grey.greyMedium};
    cursor: pointer;
  }
`;

export const SInfoList = styled.ul`
  padding: 24px;
  & > li {
    display: flex;
    gap: 24px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    & > span:first-child {
      width: 225px;
      text-align: left;
    }
  }
`;

export const SOrder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
`;

export const SContain = styled.div`
  padding: 24px;
  border: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  border-radius: 8px;

  & > span {
    display: block;
    text-align: left;
  }
`;

export const SProdList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SProdItem = styled.li`
  display: flex;
  gap: 15px;

  & > div {
    display: flex;
    flex: 1;
    text-align: left;

    & > span {
      white-space: nowrap;
      & span {
        padding-left: 10px;
        color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
      }
    }
  }

  & > span {
    white-space: nowrap;
  }
`;

export const SCheque = styled.div`
  padding: 32px 24px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};

  & > section {
    margin-bottom: 32px;
  }
`;
export const SPromoWrap = styled.div`
  margin-bottom: 32px;
`;

export const SSale = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};

  & > span:first-child {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const STotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SPriceOld = styled.div`
  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    text-decoration: line-through;
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;

  & button {
    width: 100%;
  }
`;

export const SFieldBlock = styled.div`
  margin-bottom: 24px;

  & > label {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  & a {
    text-align: left;
    text-decoration: none;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
`;

export const SCheckoutItem = styled.li<{ isDocument?: boolean }>`
  ${(props) =>
    props.isDocument
      ? `
		& > span:last-child {
			cursor: pointer;
			color: ${props.theme.mtsColor.text.primaryLink.lightMode}
		}
	`
      : ``};
`;

export const SDisclaime = styled(CustomTypography)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
