import {
  ROUTE_ASKUE_PAGE,
  ROUTE_MINING_PAGE,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_SHOP,
  ROUTE_SHOP_ITEM,
  ROUTE_ASKUE_OPP,
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_DEMO,
} from "stream-constants/route-constants";

const toCheck = [
  "",
  ROUTE_ASKUE_PAGE,
  ROUTE_MINING_PAGE,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_SHOP,
  ROUTE_SHOP_ITEM,
  ROUTE_ASKUE_OPP,
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
];

const toCheckPage = [ROUTE_ASKUE_DEMO];

export const defineHeaderView = (href: string) => {
  return toCheck.includes(href.substring(1)) ? null : "blue";
};

export const isOnlyContentPage = (href: string) => {
  return !toCheckPage.includes(href.substring(1));
};
