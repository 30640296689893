import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import { ContextSettings } from "context/context-settings";
import { ROUTE_SHOP } from "stream-constants/route-constants";
import { CustomTypography } from "components/customTypography/custom-typography";
import { MTSInput } from "components/mts-input";
import { SCategory, SPromoTags } from "../shopCard/styles";
import { formatPrice } from "../shopCard/utils";
import Icon from "components/icons";
import { ICheckCartRes, IShopProduct } from "interface/common";
import { renderPriceProduct } from "../const";
import { SCont, SLinkMore, SPrice } from "./styles";

interface IProp {
  product: IShopProduct | ICheckCartRes;
  quantity: number;
  type?: "detail" | "cart" | "service";
}

export const CartProductCard: React.FC<IProp> = ({ product, quantity, type = "cart" }) => {
  const navigate = useNavigate();
  const { setCartAmount, removeFromCart } = useContext(ContextSettings);

  return (
    <SCont>
      {type !== "service" ? <img src={product.image_url} alt={product.name} /> : null}
      <div>
        <SCategory>
          <CustomTypography variant="c1_regular" font="comp">
            {product.category}
          </CustomTypography>
        </SCategory>
        <div>
          <CustomTypography variant="h4_medium" font="comp">
            {product.name}
          </CustomTypography>
        </div>
        {!isEmpty((product as IShopProduct).promotions) ? (
          <>
            <SPromoTags>
              {(product as IShopProduct).promotions.map((promo) => (
                <div key={promo.promotion_id}>
                  <CustomTypography font="wide">{promo.promotion_bubble}</CustomTypography>
                </div>
              ))}
            </SPromoTags>
          </>
        ) : null}
      </div>
      {type === "cart" || product.price !== -1 ? (
        <div>
          <Icon.Minus onClick={() => setCartAmount({ id: product.id, action: "decr" })} />
          <MTSInput
            type="tel"
            placeholder="0"
            onChange={(event) =>
              setCartAmount({ id: product.id, quant: parseInt(event.target.value ?? "") || 1 })
            }
            value={quantity || ""}
            maxLength={4}
            style={{ textAlign: "center" }}
          />
          <Icon.Plus onClick={() => setCartAmount({ id: product.id, action: "inc" })} />
          <SPrice>
            <CustomTypography variant="h3_bold" font="comp">
              {formatPrice(product.price * quantity)} {type === "service" ? "/месяц" : null}
            </CustomTypography>
          </SPrice>
        </div>
      ) : (
        <div>
          <SPrice>
            <CustomTypography variant="h3_bold" font="comp">
              {renderPriceProduct(
                product.price,
                Boolean("promo_disclamer" in product && product?.promo_disclamer),
              )}
            </CustomTypography>
          </SPrice>
        </div>
      )}
      {type === "cart" || type === "service" ? (
        <div onClick={() => removeFromCart(product.id)}>
          <Icon.Close />
        </div>
      ) : (
        <SLinkMore onClick={() => navigate(`/${ROUTE_SHOP}/${product.id}`)}>
          <CustomTypography variant="c1_regular" font="comp">
            Подробнее
          </CustomTypography>
          <Icon.ArrowRight />
        </SLinkMore>
      )}
    </SCont>
  );
};

interface IPropService {
  product: ICheckCartRes;
  type?: "cartService";
}

export const CartServiceCard: React.FC<IPropService> = ({ product }) => {
  return (
    <SCont>
      <div>
        <div>
          <CustomTypography variant="h4_medium" font="comp">
            {product.name}
          </CustomTypography>
        </div>
        <div>
          <CustomTypography variant="p4_medium" font="comp">
            {product.type}
          </CustomTypography>
        </div>
        <div>
          <CustomTypography variant="c1_regular" font="comp">
            {product.promo_desc}
          </CustomTypography>
        </div>
      </div>
      <div style={{ justifyContent: "center" }}>
        <SPrice>
          <CustomTypography variant="h3_bold" font="comp">
            0₽*
          </CustomTypography>
        </SPrice>
      </div>
    </SCont>
  );
};
