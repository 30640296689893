import { PHONE_MASK, INN_MASK } from "stream-constants/keys-and-constants";
import { unformatPhone } from "utils/formatters";

const correctPhone = (value?: string): boolean => {
  if (!value) return false;
  if (value?.length === PHONE_MASK.length) {
    const phoneWM = unformatPhone(value);

    return phoneWM.length === 10;
  } else {
    const phone: string = value?.replace(/\D/g, "");

    return phone.length === 0 || phone.length === 1 || phone.length === 10;
  }
};

const requiredPhone = (value?: string): boolean => {
  return value?.length === 10 || value?.length === PHONE_MASK.length;
};

const correctEmail = (value: string): boolean => {
  if (value) {
    const emailCheck =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return emailCheck.test(value) ? true : false;
  }

  return true;
};

const correctUserName = (value?: string): boolean => {
  if (value) {
    const userNameCheck = /^[a-zа-яё\s]+$/iu;

    return userNameCheck.test(value) ? true : false;
  }

  return true;
};

const requiredUserName = (value: string): boolean => {
  return value?.length > 0;
};

const correctInn = (value?: string): boolean => {
  if (value) {
    const innCheck = /^\d+$/;

    return innCheck.test(value) ? true : false;
  }

  return true;
};

const requiredInn = (value: string): boolean => {
  return value?.length === 10 || value?.length === INN_MASK.length;
};

const correctOgrn = (value?: string) => /^\d{13}$/.test(value ?? "");

const correctOgrnip = (value?: string) => /^\d{15}$/.test(value ?? "");

const correctLengthVal20 = (value?: string) => /^\d{20}$/.test(value ?? "");

const correctLengthVal9 = (value?: string) => /^\d{9}$/.test(value ?? "");

const requiredOrganizationName = (value: string): boolean => {
  return value?.length > 0;
};

const correctOrganization = (value?: string) => /^[А-Я]+[^a-zA-Z]+$/.test(value ?? "");

const correctSn = (value?: string) => /^\d{5,}$/.test(value ?? "");

const validationMethod = {
  correctPhone,
  requiredPhone,
  correctEmail,
  correctUserName,
  requiredUserName,
  correctInn,
  requiredInn,
  requiredOrganizationName,
  correctOrganization,
  correctOgrn,
  correctOgrnip,
  correctLengthVal20,
  correctLengthVal9,
  correctSn,
};

export default validationMethod;
