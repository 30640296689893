import { IShopProduct } from "interface/common";
import { isEmpty } from "lodash";
import { compareDesc, compareAsc } from "date-fns";

export const checkBubble = "+ АСКУЭ 12 МЕСЯЦЕВ";

export const composeBackendFilter = <T>(somearr): T =>
  somearr?.reduce(
    (sum, cur) => ({
      ...sum,
      ...(!isEmpty(cur.value) ? { [cur.column]: cur.value } : {}),
    }),
    {},
  );

export const title = "ENERGYTOOL\nSHOP";
export const subtext =
  "Комплекс программных решений, которые помогут оптимизировать энергетические\nрасходы вашей организации, выявить подозрительную активность абонентов и\nавтоматизировать процессы сбора и анализа данных ";

export const hardcodedDirection = [
  { val: "price_desc", friendlyName: "По убыванию цены" },
  { val: "price_asc", friendlyName: "По возрастанию цены" },
  { val: "date_desc", friendlyName: "Сначала новые добавленные" },
  { val: "date_asc", friendlyName: "Сначала старые добавленные" },
];

export const SHOP_FILTER_DEFAULT = {
  categorys: [],
  types: [],
  conn_types: [],
};

export const SHOP_SORT_DEFAULT = "price_desc";

export const SHOP_SCHEME_HARDCODED = {
  categorys: [
    { id: 1, name: "Cчетчик учета электроэнергии" },
    { id: 2, name: "Модем" },
    { id: 3, name: "Услуги и лицензии" },
    { id: 4, name: "Специальные предложения" },
  ],
  types: [
    { id: 1, name: "Однофазный" },
    { id: 2, name: "Трехфазный" },
  ],
  conn_types: [
    { id: 1, name: "GSM/GPRS" },
    { id: 2, name: "NB-IoT" },
  ],
};

export const calcSort = (a: IShopProduct, b: IShopProduct, sort: string) => {
  switch (sort) {
    case "price_desc":
      return b.price - a.price;
    case "price_asc":
      return a.price - b.price;
    case "date_desc":
      return compareDesc(new Date(a.date), new Date(b.date));
    case "date_asc":
      return compareAsc(new Date(a.date), new Date(b.date));
    default:
      return 0;
  }
};

export const titleByKey = {
  categorys: "Категория",
  types: "Типы счетчиков",
  conn_types: "Вид связи",
};

export const promoQuery = "?categorys=Специальные предложения";

export const calcDefFilter = (query: URLSearchParams) => {
  if (query.has("categorys")) {
    let val = query.get("categorys");

    return {
      categorys: [val],
      types: [],
      conn_types: [],
    } as any;
  }
  return SHOP_FILTER_DEFAULT;
};

export const seo = {
  title: "Магазин - EnergyTool",
  description:
    "Комплекс программных и аппаратных решений, которые помогут оптимизировать расходы вашей организации, внедрить АСКУЭ, выявить подозрительную активность абонентов и автоматизировать процессы сбора и анализа данных с приборов учета.",
};
