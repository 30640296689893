import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: white;
  text-align: left;

  & > div {
    margin: 0 auto;
    padding: 48px 20px 0 20px;
    max-width: 1304px;
    width: 100%;
    height: auto;
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 32px;

  & > div {
    &:first-of-type {
      & > * {
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }

    &:last-of-type {
      & > section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        padding: 0 24px;
        margin-bottom: 32px;
        border-radius: 8px;
        background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
      }

      & button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;

export const SInfo = styled(CustomTypography)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SDisclaim = styled.div`
  & > .MuiTypography-root {
    display: block;
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;
