import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      padding: 0,
      paddingLeft: "9px",
      "&:hover": {
        backgroundColor: "transparent",
      },

      "& .MuiSvgIcon-root": {
        height: "24px",
        width: "24px",
        fill: theme.mtsColor.grey.greyClay,
      },
      "& + .MuiTypography-root": {
        paddingLeft: "5px",
      },
    },
  },
  colorBlack: {
    "&.MuiCheckbox-root": {
      "&.Mui-checked": {
        color: theme.mtsColor.background.primary.darkMode,
        "& .MuiSvgIcon-root": {
          fill: theme.mtsColor.background.primary.darkMode,
        },
      },
    },
  },
  colorRed: {
    "&.MuiCheckbox-root": {
      "&.Mui-checked": {
        color: theme.mtsColor.red,
        "& .MuiSvgIcon-root": {
          fill: theme.mtsColor.red,
        },
      },
    },
  },
  textGrey: {
    "& + .MuiTypography-root": {
      color: theme.mtsColor.grey.greyDark,
    },
  },
  error: {
    "&.MuiCheckbox-root": {
      color: theme.mtsColor.red,
    },
  },
  sizeS: {
    "&.MuiCheckbox-root": {
      height: "16px",
      width: "16px",
    },
  },
  sizeM: {
    "&.MuiCheckbox-root": {
      height: "24px",
      width: "24px",
    },
  },
  sizeL: {
    "&.MuiCheckbox-root": {
      height: "32px",
      width: "32px",
    },
  },
}));

export default useStyles;
