import { useContext } from "react";
import { SBody, SBtns, SCard, SImg } from "./styles";
import { Link } from "react-router-dom";
import { ContextSettings } from "context/context-settings";
import { useBreakpoint } from "hooks/useBreakpoint";
import { MTSButton } from "components/mts-button/mts-button";
import { CustomTypography } from "components/customTypography/custom-typography";

interface ProductCardProps {
  title?: string;
  description?: string;
  nameBtn?: string;
  link?: any;
  isCallbackBlock?: boolean;
  img?: string;
}

export const ProductCard = (props: ProductCardProps) => {
  const { title, description, nameBtn, isCallbackBlock, link, img } = props;
  const context = useContext(ContextSettings);
  const { isMobile } = useBreakpoint();

  return (
    <SCard isCallbackBlock={isCallbackBlock}>
      <Link to={`/${link}`}>
        {img && <SImg src={img} alt={title} />}
        <SBody>
          <h4>
            <CustomTypography variant="h3_medium" font="wide">
              {title}
            </CustomTypography>
          </h4>
          <p>
            <CustomTypography variant="p4_regular" font="comp">
              {description}
            </CustomTypography>
          </p>
        </SBody>
        <SBtns>
          {link && (
            <Link to={`/${link}`}>
              <MTSButton size="S" variant="primary" onClick={() => {}}>
                {nameBtn}
              </MTSButton>
            </Link>
          )}
          {isCallbackBlock && (
            <MTSButton
              size={isMobile() ? "M" : "S"}
              variant="primary"
              onClick={() => context.changeStateFormConsultation(true)}
            >
              {nameBtn}
            </MTSButton>
          )}
        </SBtns>
      </Link>
    </SCard>
  );
};
