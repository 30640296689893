import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MobileMenuBtn = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="19" width="24" height="2.66667" rx="1" fill="white"/>
      <rect y="11" width="24" height="2.66667" rx="1" fill="white"/>
      <rect y="3" width="24" height="2.66667" rx="1" fill="white"/>
    </SvgIcon>
  );
};

export default MobileMenuBtn;
