import { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";

import { FALLBACK_MSG, schema } from "./validation-schema";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { useSubmitFormConsultation } from "hooks/hooks";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { CustomTypography } from "components/customTypography/custom-typography";
import { unformatPhone } from "utils/formatters";

import { SBottom, SBtnCancel, SBtns, SFieldBlock, SLink, SSubtitle, SText, STitle } from "./styles";

type CommonProps = {
  contact?: any;
  buttonTitle?: string;
  handleCancelClick?: any;
};

export const FormConsultation: FC<CommonProps> = ({ contact, handleCancelClick }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: contact || {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const mutation = useSubmitFormConsultation();

  const onSubmit = (sbmtData: any) => {
    mutation.mutate({
      ...sbmtData,
      phone: unformatPhone(sbmtData.phone),
    });
  };

  return (
    <>
      {mutation?.isSuccess ? (
        <ModalNotifyBody
          title="Заявка отправлена"
          textDescrioption="Мы свяжемся с вами в течение рабочего дня с 9:00 до 18:00"
          isSuccess
          close={handleCancelClick}
        />
      ) : mutation?.isError ? (
        <ModalNotifyBody
          title="Произошла ошибка"
          isError
          textDescrioption={(mutation?.error as any).message ?? FALLBACK_MSG}
          close={handleCancelClick}
        />
      ) : (
        <>
          <STitle>
            <CustomTypography variant="h3_medium" font="comp">
              Заявка на консультацию
            </CustomTypography>
          </STitle>
          <SSubtitle>
            <CustomTypography variant="p4_regular" font="comp">
              Оставьте свои данные для обратной связи
            </CustomTypography>
          </SSubtitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SFieldBlock>
              <MTSInput
                {...register("userName")}
                label="Ваше имя"
                errorMessage={errors?.userName?.message}
                control={control}
              />
            </SFieldBlock>
            <SFieldBlock>
              <InputMask mask={PHONE_MASK} {...register("phone")} defaultValue={contact?.phone}>
                {() => (
                  <MTSInput
                    {...register("phone")}
                    label="Номер телефона"
                    errorMessage={errors?.phone?.message}
                    control={control}
                  />
                )}
              </InputMask>
            </SFieldBlock>
            <SFieldBlock>
              <MTSInput
                {...register("email")}
                label="Электронная почта"
                placeholder="Не обязательно"
                control={control}
                errorMessage={errors?.email?.message}
              />
            </SFieldBlock>
            <SBottom>
              <SText>
                <CustomTypography variant="p4_regular" font="comp">
                  Нажимая кнопку «Отправить», вы соглашаетесь c{" "}
                  <SLink to={ROUTE_USER_AGREEMENT} target="_blank">
                    условиями обработки персональных данных
                  </SLink>
                </CustomTypography>
              </SText>
              <SBtns>
                <div>
                  <MTSButton size="M" variant="primary" onClick={() => {}} type="submit">
                    Отправить
                  </MTSButton>
                </div>
                <SBtnCancel>
                  <MTSButton size="M" variant="outline_dark" onClick={handleCancelClick}>
                    Отмена
                  </MTSButton>
                </SBtnCancel>
              </SBtns>
            </SBottom>
          </form>
        </>
      )}
    </>
  );
};
