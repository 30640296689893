import styled from "@emotion/styled";
import { device } from "theme";
import { HEADER_HEIGHT } from "components/header/styles";

export const maxWidthSidebar = "256px";

export const SApp = styled.div`
  position: relative;
  width: 100%;
  min-height: inherit;
  height: 100% !important;
  text-align: center;
`;

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 100%;
  height: 100%;
  padding-top: ${HEADER_HEIGHT}px;

  @media ${device.desktopPlus} {
    overflow: auto;
  }
`;
