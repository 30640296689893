import styled from "@emotion/styled";
import { device } from "theme";
import { HEADER_HEIGHT } from "components/header/styles";

export const SLanding = styled.section<{ isFullScreen?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};

  ${(props) =>
    props.isFullScreen
      ? `

	& section {
		min-height: calc(100vh - ${HEADER_HEIGHT}px);
	}
	`
      : `
	  	height: 380px;

		  & article {
			  height: 100%;
		  }

		  & section {
			  height: 100%;
			  top: 0
		  }
	  `}
`;

export const SArticle = styled.article`
  position: relative;
  min-height: auto;
`;

export const SGeo = styled.section`
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 0;
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};

  @media ${device.miniTabletNeg} {
    background-position: left;
  }
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

export const STitle = styled.span<{ isFullScreen?: boolean }>`
  display: flex;
  & .MuiTypography-root {
    text-align: left;
    text-transform: uppercase;
    color: ${(props) => props.theme.mtsColor.text.inverted.lightMode};
    white-space: pre-wrap;

    @media ${device.miniTabletNeg} {
      font-size: 32px;
    }

    @media ${device.mobileNeg} {
      font-size: 24px;
    }

    ${(props) =>
      props.isFullScreen
        ? `

	  @media ${device.miniTabletNeg} {
		font-size: 32px;
	  }
	  `
        : `
		font-size: 44px;
	  `}

    @media ${device.mobileNeg} {
      font-size: 24px;
    }
  }
`;

export const SText = styled.span`
  display: flex;
  max-width: 745px;
  & .MuiTypography-root {
    text-align: left;
    color: ${(props) => props.theme.mtsColor.text.inverted.lightMode};
  }
`;

export const SSubtext = styled.span`
  display: flex;
  max-width: 705px;
  & .MuiTypography-root {
    text-align: left;
    color: ${(props) => props.theme.mtsColor.text.primary.darkMode};
  }
`;

export const SCardBlock = styled.div`
  padding-top: 48px;
`;

export const SBtns = styled.div`
  display: flex;
  padding-top: 44px;
  gap: 40px;

  & button {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media ${device.miniTabletNeg} {
    flex-direction: column;
    justify-content: center;
    & button {
      width: 280px;
    }
  }
  @media ${device.mobileNeg} {
    & button {
      width: 100%;
    }
  }
`;
