import { ReactElement } from "react";
import useStyles from "./styles";
import { Checkbox as CheckboxMui, FormControlLabel } from "@mui/material";
import cn from "classnames";

interface IChkbx {
  id?: string;
  label?: string | ReactElement;
  name?: string;
  size?: "S" | "M" | "L";
  checked?: boolean;
  onCheck?: Function;
  onChange?: Function;
  value?: string | number;
  color?: "black" | "red";
  textGrey?: boolean;
  hasError?: boolean;
  disabled?: boolean;
}

export const Checkbox: React.FC<IChkbx> = ({
  id,
  label,
  name,
  checked,
  onChange,
  onCheck,
  size,
  value,
  color = "black",
  textGrey = false,
  hasError,
  disabled,
}) => {
  const classes = useStyles();

  const checkboxClasses = cn({
    [classes.checkbox]: true,
    [classes.sizeS]: size === "S",
    [classes.sizeM]: size === "M",
    [classes.sizeL]: size === "L",
    [classes.colorRed]: color === "red",
    [classes.colorBlack]: color === "black",
    [classes.textGrey]: textGrey,
    [classes.error]: hasError,
  });

  return (
    <FormControlLabel
      label={label ?? ""}
      control={
        <CheckboxMui
          id={id}
          name={name}
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onCheck?.();
            onChange?.(e.target.value);
          }}
          className={checkboxClasses}
          value={value}
          disabled={disabled}
        />
      }
    />
  );
};
