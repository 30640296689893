import { Helmet } from "react-helmet";

import { Spacer } from "components/spacer/spacer.component";
import IntroSection from "components/intro-section/intro-section";
import { AdditInfoList } from "components/additional-info-list/additional-info";

import {
  additionalInfo,
  blockFour,
  blockOne,
  blockThree,
  blockTwo,
  headerFour,
  headerOne,
  headerThree,
  headerTwo,
  seo,
} from "./const";
import {
  SBlockOne,
  SWideHeader,
  SMain,
  SCaption,
  SBlockTwo,
  SBlockThree,
  SBlockFour,
  SItem,
  SCompHeader,
} from "./styles";

import bgImg from "./images/hero_bg.png";

export const PossibilityPlatform = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <IntroSection
          title={"Возможности\nплатформы"}
          subtext="Облачная платформа учета обеспечивает решение самых требовательных задач"
          bgImg={bgImg}
        />
        <SBlockOne>
          <section>
            <SWideHeader variant="h1_medium" font="wide">
              {headerOne}
            </SWideHeader>
            <Spacer value="48px" />
            <article>
              {blockOne.map((a, ind) => (
                <div key={ind}>
                  <img src={a.pic} alt={a.title} />
                  <SWideHeader variant="h3_medium">{a.title}</SWideHeader>
                  <Spacer value="18px" />
                  {a.steps.map((b, indx) => (
                    <SCaption variant="c1_regular" font="comp" key={indx}>
                      {b}
                    </SCaption>
                  ))}
                </div>
              ))}
            </article>
          </section>
        </SBlockOne>
        <SBlockTwo>
          <section>
            <div>
              <SWideHeader variant="h1_medium">{headerTwo}</SWideHeader>
              <Spacer value="48px" />
              <article>
                {blockTwo.map((c, i) => (
                  <SCaption variant="p4_regular" key={i}>
                    {c}
                  </SCaption>
                ))}
              </article>
            </div>
            <section>
              <Spacer value="48px" />
              <AdditInfoList data={additionalInfo} />
            </section>
          </section>
        </SBlockTwo>
        <SBlockThree>
          <section>
            <div>
              <SWideHeader variant="h1_medium">{headerThree}</SWideHeader>
              <Spacer value="48px" />
              <article>
                {blockThree.map((c, i) => (
                  <SCaption variant="p4_regular" key={i}>
                    {c}
                  </SCaption>
                ))}
              </article>
            </div>
          </section>
        </SBlockThree>
        <SBlockFour>
          <section>
            <SWideHeader variant="h2_medium">{headerFour}</SWideHeader>
            <Spacer value="48px" />
            <article>
              {blockFour.map((a, i) => (
                <SItem key={i} bg={a.pic}>
                  <SCompHeader variant="h4_medium" font="comp">
                    {a.title}
                  </SCompHeader>
                  <Spacer value="8px" />
                  <SCompHeader variant="p4_regular" font="comp">
                    {a.sub}
                  </SCompHeader>
                </SItem>
              ))}
            </article>
          </section>
        </SBlockFour>
      </SMain>
    </>
  );
};
