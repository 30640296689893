import styled from "@emotion/styled";
import { device } from "theme";
import { Link } from "react-router-dom";

export const STitle = styled.h2`
  text-align: left;
  margin-bottom: 8px;
`;

export const SSubtitle = styled.div`
  text-align: left;
  margin-bottom: 24px;

  @media ${device.tabletNeg} {
    flex-direction: column;
  }
`;

export const SFieldBlock = styled.div`
  margin-bottom: 24px;
`;

export const SBottom = styled.div`
  display: flex;

  @media ${device.miniTabletNeg} {
    flex-direction: column;
  }
`;

export const SText = styled.div`
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.grey.greyDark};
  }

  @media ${device.miniTabletNeg} {
    flex-direction: column;
    margin-bottom: 48px;
  }
`;

export const SLink = styled(Link)`
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;

export const SBtns = styled.div`
  margin-left: 16px;

  @media ${device.miniTabletNeg} {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & div:not(:last-of-type) {
      margin-bottom: 32px;
    }
    & .MuiButtonBase-root {
      min-width: 287px;
    }
  }
`;

export const SBtnCancel = styled.div`
  display: none;

  @media ${device.miniTabletNeg} {
    display: block;
  }
`;
