import { useEffect, useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import { isEmpty } from "lodash";
import theme from "theme";

import { useInformationFl, useInformationUl } from "hooks/hooks";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { schemaInformation } from "./validation-schema";
import { MTSInput } from "components/mts-input";
import { Radio } from "components/radio/radio.component";
import { InputLabel } from "components/input-label";
import { Checkbox } from "components/checkbox/checkbox";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { DragDropFile } from "components/drag-drop-file/drag-drop-file";
import { IInformationData } from "interface/common";
import { CustomTypography } from "components/customTypography/custom-typography";
import { composeSubmitData, toastError, toastSucc } from "./utils";
import { USER_IP, USER_UL } from "./const";
import { SControls, SFieldAdress, SWrap } from "./styles";

interface IForm {
  formDefault: any;
  onClose: () => void;
}

export const FormUserInfo: React.FC<IForm> = ({ onClose, formDefault }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors, isDirty },
    getValues,
    setValue,
    setError,
  } = useForm({
    defaultValues: formDefault,
    resolver: yupResolver(schemaInformation),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const isFileReplaced = useRef(false);

  const mutationFl = useInformationFl();
  const mutationUl = useInformationUl();

  const [watchFile, watchType] = useWatch({ control, name: ["file", "type_user"] });
  const isUrik = watchType === USER_UL;
  const watchUrFields = useWatch({
    control,
    name: ["ur_city", "ur_street", "ur_house", "ur_flat", "ur_index"],
  });
  const watchPostFields = useWatch({
    control,
    name: ["post_city", "post_street", "post_house", "post_flat", "post_index"],
  });
  const [watchUrAdr, watchPostAdr] = useWatch({ control, name: ["ur_adress", "post_adress"] });

  const handleCloseForm = () => {
    onClose();
    reset();
  };

  const onSuccess = () => {
    handleCloseForm();
    toastSucc();
  };

  const onSubmit = async (sbmtData: any) => {
    const isForUrik = sbmtData?.type_user === USER_UL;
    let formData: IInformationData = composeSubmitData(sbmtData, isForUrik, isFileReplaced.current);

    if (isForUrik) {
      if (!formData.get("file")) {
        formData.append("file", new Blob(), "emptyFile");
      }
      mutationUl.mutate(formData, {
        onSuccess: () => onSuccess(),
        onError: (err) => toastError(err),
      });
    } else {
      mutationFl.mutate(formData, {
        onSuccess: () => onSuccess(),
        onError: (err) => toastError(err),
      });
    }
  };

  useEffect(() => {
    setValue("ur_adress", watchUrFields.join(", "));
  }, [watchUrFields, setValue]);

  useEffect(() => {
    setValue("post_adress", watchPostFields.join(", "));
  }, [watchPostFields, setValue]);

  useEffect(() => {
    if (watchFile == null && isUrik) {
      isFileReplaced.current = true;
      setError("file", {
        type: "userFile",
        message: "Поле не заполнено",
      });
    } else {
      clearErrors("file");
    }
  }, [clearErrors, setError, watchFile, isUrik]);

  return (
    <SWrap>
      <CustomTypography variant="h3_medium" font="comp">
        Укажите информацию о заказчике
      </CustomTypography>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Spacer value="32px" />
        <Controller
          name="type_user"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <section>
              <Radio
                id="ipshnik"
                name="ipshnik"
                value={USER_IP}
                checked={value === USER_IP}
                onChange={onChange}
                text={USER_IP}
                hasError={error != null && !value}
              />
              <Radio
                id="urik"
                name="urik"
                value={USER_UL}
                checked={value === USER_UL}
                onChange={onChange}
                text={USER_UL}
                hasError={error != null && !value}
              />
            </section>
          )}
        />
        <Spacer value="32px" />
        {isUrik ? (
          <MTSInput
            label={"Наименование организации"}
            control={control}
            {...register("tenant_name", {
              required: true,
            })}
            placeholder={"Наименование организации"}
            errorMessage={errors?.tenant_name?.message}
          />
        ) : (
          <MTSInput
            label={"Наименование ИП"}
            control={control}
            {...register("tenant_name", {
              required: true,
            })}
            placeholder={"ИП Иванов П.С."}
            errorMessage={errors?.tenant_name?.message}
          />
        )}
        <Spacer value="32px" />
        <section>
          <MTSInput
            label="ИНН"
            control={control}
            {...register("inn", {
              required: true,
            })}
            errorMessage={errors?.inn?.message}
          />
          {isUrik ? (
            <>
              <MTSInput
                label="КПП"
                control={control}
                {...register("kpp", {
                  required: true,
                })}
                errorMessage={errors?.kpp?.message}
              />
              <MTSInput
                label="ОГРН"
                control={control}
                {...register("ogrn", {
                  required: true,
                })}
                errorMessage={errors?.ogrn?.message}
              />
            </>
          ) : (
            <MTSInput
              label="ОГРНИП"
              control={control}
              {...register("ogrnip", {
                required: true,
              })}
              errorMessage={errors?.ogrnip?.message}
            />
          )}
        </section>
        <Spacer value="32px" />
        <>
          <InputLabel label="Юридический адрес" />
          <Spacer value="4px" />
          <section>
            <MTSInput
              control={control}
              {...register("ur_city", {
                required: true,
              })}
              placeholder="Город"
              errorMessage={errors?.ur_city?.message}
            />
            <MTSInput
              control={control}
              {...register("ur_street", {
                required: true,
              })}
              placeholder="Улица"
              errorMessage={errors?.ur_street?.message}
            />
          </section>
          <Spacer value="24px" />
          <SFieldAdress>
            <MTSInput
              control={control}
              {...register("ur_house", {
                required: true,
              })}
              placeholder="Дом"
              errorMessage={errors?.ur_house?.message}
            />
            <MTSInput
              control={control}
              {...register("ur_flat", {
                required: true,
              })}
              placeholder={isUrik ? "Оф." : "Кв."}
              errorMessage={errors?.ur_flat?.message}
            />
            <MTSInput
              control={control}
              {...register("ur_index", {
                required: true,
              })}
              placeholder="Индекс"
              errorMessage={errors?.ur_index?.message}
            />
          </SFieldAdress>
        </>
        <Spacer value="32px" />
        {isUrik ? (
          <>
            <InputLabel label="Почтовый адрес" />
            <Spacer value="4px" />
            <section>
              <MTSInput
                control={control}
                {...register("post_city", {
                  required: true,
                })}
                placeholder="Город"
                errorMessage={errors?.post_city?.message}
              />
              <MTSInput
                control={control}
                {...register("post_street", {
                  required: true,
                })}
                placeholder="Улица"
                errorMessage={errors?.post_street?.message}
              />
            </section>
            <Spacer value="24px" />
            <SFieldAdress>
              <MTSInput
                control={control}
                {...register("post_house", {
                  required: true,
                })}
                placeholder="Дом"
                errorMessage={errors?.post_house?.message}
              />
              <MTSInput
                control={control}
                {...register("post_flat", {
                  required: true,
                })}
                placeholder="Оф."
                errorMessage={errors?.post_flat?.message}
              />
              <MTSInput
                control={control}
                {...register("post_index", {
                  required: true,
                })}
                placeholder="Индекс"
                errorMessage={errors?.post_index?.message}
              />
            </SFieldAdress>
            <Spacer value="12px" />
            <Controller
              name="copy_ur_adress"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    value="true"
                    checked={watchUrAdr === watchPostAdr}
                    onCheck={(a) => {
                      if (a !== value) {
                        reset(
                          {
                            ...getValues(),
                            post_city: getValues("ur_city"),
                            post_street: getValues("ur_street"),
                            post_house: getValues("ur_house"),
                            post_flat: getValues("ur_flat"),
                            post_index: getValues("ur_index"),
                          },
                          { keepErrors: true },
                        );
                      }
                    }}
                    label={
                      <CustomTypography
                        variant="c1_regular"
                        font="comp"
                        styles={{ color: theme.mtsColor.text.secondary.lightMode }}
                      >
                        Совпадает с юридическим адресом
                      </CustomTypography>
                    }
                    color="red"
                    textGrey
                  />
                );
              }}
            />
            <Spacer value="32px" />
          </>
        ) : null}
        <MTSInput
          label="Директор"
          placeholder="Иванов Петр Сергеевич"
          control={control}
          {...register("owner_name", {
            required: true,
          })}
          errorMessage={errors?.owner_name?.message}
        />
        <Spacer value="32px" />
        {isUrik ? (
          <>
            <Controller
              name="file"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <DragDropFile
                    name="controlled-file"
                    label="Выберите файл или переместите его сюда"
                    tooltip="Приложите цифровую копию устава компании или другого документа, на основании которого действует указанный руководитель"
                    onChange={onChange}
                    hasError={!!error?.message}
                    file={value}
                  />
                );
              }}
            />
            <Spacer value="32px" />
          </>
        ) : null}
        <section>
          <InputMask mask={PHONE_MASK} {...register("phone")}>
            {() => (
              <MTSInput
                label="Телефон/факс"
                control={control}
                {...register("phone", {
                  required: true,
                })}
                errorMessage={errors?.phone?.message}
              />
            )}
          </InputMask>
          <MTSInput
            label="Электронная почта"
            control={control}
            {...register("email", {
              required: true,
            })}
            errorMessage={errors?.email?.message}
          />
        </section>
        <Spacer value="32px" />
        {isUrik ? (
          <>
            <section>
              <InputMask mask={PHONE_MASK} {...register("phone_add")}>
                {() => (
                  <MTSInput
                    label="Дополнительный телефонный номер"
                    control={control}
                    {...register("phone_add", {
                      required: true,
                    })}
                    errorMessage={errors?.phone_add?.message}
                  />
                )}
              </InputMask>
            </section>
            <Spacer value="32px" />
          </>
        ) : null}
        <SControls>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isEmpty(errors) || !isDirty}
          >
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={handleCloseForm}>
            Отмена
          </MTSButton>
        </SControls>
        <Spacer value="24px" />
      </form>
    </SWrap>
  );
};
