import { isEmpty } from "lodash";
import { Checkbox } from "components/checkbox/checkbox";

import { SBody, SBoxWrap, STitle } from "./styles";
import { Spacer } from "components/spacer/spacer.component";

interface IProps {
  isAllChecked: boolean;
  toggleAll: Function;
  toggleSingle: Function;
  currFilters: IKek;
  filterKey: string;
  targetArray?: {
    id: number;
    name: string | null;
    friendlyName?: string;
  }[];
  titleByKey: { [key: string]: string };
}
export type IKek = { [key: string]: string[] };

export const ChxRenderer: React.FC<IProps> = ({
  targetArray,
  filterKey,
  isAllChecked,
  toggleAll,
  toggleSingle,
  currFilters,
  titleByKey,
}) => {
  return (
    <SBoxWrap>
      <STitle variant="h4_medium" font="comp">
        {titleByKey[filterKey]}
      </STitle>
      <Spacer value="12px" />
      {targetArray && !isEmpty(targetArray) ? (
        <SBody>
          <Checkbox
            id={`all${filterKey}`}
            label="Выбрать все"
            name={`all${filterKey}`}
            onCheck={() => toggleAll(filterKey, targetArray, isAllChecked)}
            checked={isAllChecked}
          />
          {targetArray.map((item: any) => {
            return (
              <Checkbox
                key={`${item.id}_${filterKey}`}
                label={item.friendlyName ?? item.name}
                name={`${item.id}_${filterKey}`}
                checked={currFilters[filterKey]?.includes(item.name) || false}
                id={`${item.id}_${filterKey}`}
                onCheck={() => toggleSingle(filterKey, item.name)}
              />
            );
          })}
        </SBody>
      ) : null}
    </SBoxWrap>
  );
};
