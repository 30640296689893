import { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";

import { FALLBACK_MSG, schema } from "./validation-schema";
import { INN_MASK, PHONE_MASK } from "stream-constants/keys-and-constants";
import { ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { useRegistrationUser } from "hooks/hooks";

import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { CustomTypography } from "components/customTypography/custom-typography";
import { unformatPhone } from "utils/formatters";
import { STitle, SSubtitle, SFieldBlock, SText, SLink, SBtns, SBtnCancel, SBottom } from "./styles";

type CommonProps = {
  contact?: any;
  buttonTitle?: string;
  handleCancelClick?: any;
};

export const FormReg: FC<CommonProps> = ({ handleCancelClick }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const mutation = useRegistrationUser();

  const onSubmit = (sbmtData: any) => {
    mutation.mutate({
      ...sbmtData,
      phone: unformatPhone(sbmtData.phone),
    });
  };

  return (
    <>
      {mutation?.isSuccess ? (
        <ModalNotifyBody
          title="Вы успешно зарегестрированы"
          textDescrioption="Для входа в личный кабинет используйте указанный номер телефона "
          isSuccess
          close={handleCancelClick}
        />
      ) : mutation?.isError ? (
        <ModalNotifyBody
          title="Произошла ошибка"
          isError
          textDescrioption={(mutation?.error as any).message ?? FALLBACK_MSG}
          close={handleCancelClick}
        />
      ) : (
        <>
          <STitle>
            <CustomTypography variant="h3_medium" font="comp">
              Регистрация Демо аккаунта
            </CustomTypography>
          </STitle>
          <SSubtitle>
            <CustomTypography variant="p4_regular" font="comp">
              Укажите информацию о вашей организации и свои данные для обратной связи
            </CustomTypography>
          </SSubtitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SFieldBlock>
              <MTSInput
                {...register("companyName")}
                label="Название организации"
                errorMessage={errors?.companyName?.message}
                control={control}
              />
            </SFieldBlock>
            <SFieldBlock>
              <InputMask mask={INN_MASK} {...register("inn")}>
                {() => (
                  <MTSInput
                    label="ИНН"
                    {...register("inn", {
                      required: true,
                    })}
                    errorMessage={errors?.inn?.message}
                    control={control}
                  />
                )}
              </InputMask>
            </SFieldBlock>
            <SFieldBlock>
              <MTSInput
                {...register("userName")}
                label="ФИО"
                errorMessage={errors?.userName?.message}
                control={control}
              />
            </SFieldBlock>
            <SFieldBlock>
              <InputMask mask={PHONE_MASK} {...register("phone")}>
                {() => (
                  <MTSInput
                    {...register("phone")}
                    label="Номер телефона"
                    errorMessage={errors?.phone?.message}
                    control={control}
                  />
                )}
              </InputMask>
            </SFieldBlock>
            <SFieldBlock>
              <MTSInput
                {...register("email")}
                label="Электронная почта"
                placeholder="Не обязательно"
                control={control}
                errorMessage={errors?.email?.message}
              />
            </SFieldBlock>
            <SBottom>
              <SText>
                <CustomTypography variant="p4_regular" font="comp">
                  Нажимая кнопку «Отправить», вы соглашаетесь c{" "}
                  <SLink to={`/${ROUTE_USER_AGREEMENT}`} target="_blank">
                    условиями обработки персональных данных
                  </SLink>
                </CustomTypography>
              </SText>
              <SBtns>
                <div>
                  <MTSButton size="M" onClick={() => {}} type="submit">
                    Отправить
                  </MTSButton>
                </div>
                <SBtnCancel>
                  <MTSButton size="M" variant="outline_dark" onClick={handleCancelClick}>
                    Отмена
                  </MTSButton>
                </SBtnCancel>
              </SBtns>
            </SBottom>
          </form>
        </>
      )}
    </>
  );
};
