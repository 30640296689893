import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";

import { device } from "theme";
import { DeskMenu } from "./components/desk-menu/desk-menu";
import { Cart } from "./components/cart/cart";
import { MTSButton } from "components/mts-button/mts-button";

export const HEADER_HEIGHT = 64;

export const SHeader = styled.header<{ bg?: string | null }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  z-index: 20;
  display: flex;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};

  ${(props) => props.bg === "blue" && `background-color: ${props.theme.mtsColor.base.darkNight} `};
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

export const SBody = styled.div`
  display: flex;
  height: 100%;
`;

export const SLogo = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 84px;
  text-decoration: none;

  @media ${device.miniTabletNeg} {
    margin-right: 28px;

    & > svg {
      height: 13px;
      width: auto;
    }

    & > .MuiTypography-root {
      font-size: 16px;
      line-height: 1;
    }
  }
`;

export const SNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  & > ${DeskMenu} {
    margin-right: 48px;
  }
`;

export const SShop = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  text-decoration: none;
  color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  transition: color 0.3s linear;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: transparent;
  }

  &:hover {
    color: white;
  }

  &.active {
    color: white;
    &:after {
      background: ${(props) => props.theme.mtsColor.red};
    }
  }
`;

export const SMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & li {
    height: 100%;
  }

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 4px;
      width: 100%;
      background-color: transparent;
      transition: all 0.3s ease;
    }
  }

  & li:not(:last-child) {
    margin-right: 48px;
  }
`;

export const SBtnLogIn = styled.div`
  margin-left: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & ${Cart} {
    margin-right: 32px;
  }

  @media ${device.miniTabletNeg} {
    & ${MTSButton} {
      display: none;
    }
  }

  & .MuiButtonBase-root {
    @media ${device.miniTabletNeg} {
      min-width: inherit;
    }

    @media ${device.tabletNeg} {
      margin-right: 40px;
    }
  }
`;

export const SOutBtn = styled(MTSButton)`
  &:hover {
    & .MuiButtonBase-root {
      & svg path {
        fill: black;
      }
    }
  }
`;
