import { CustomTypography } from "components/customTypography/custom-typography";
import { SSectionTitle } from "./styles";

interface SectionTitleProps {
  title: string;
  isWhite?: boolean;
  styles?: React.CSSProperties;
}

export const SectionTitle = (props: SectionTitleProps) => {
  const { title, isWhite, styles } = props;

  return (
    <SSectionTitle isWhite={isWhite} style={styles}>
      <CustomTypography variant="h1_medium" font="wide">
        {title}
      </CustomTypography>
    </SSectionTitle>
  );
};
